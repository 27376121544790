<template>
  <div>
    <ValidationObserver v-if="isInputProcess" v-slot="{ invalid }">
      <div
        class="sm:grid sm:grid-cols-4 sm:gap-12 sm:gap-y-6 border rounded-3xl shadow-xl p-8 sm:p-12 w-full sm:w-4/5 lg:w-3/5 justify-items-start mx-auto"
      >
        <ValidationProvider
          name="name"
          rules="required|max:100"
          class="w-full col-span-2"
        >
          <div class="flex">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-user"
              size="lg"
            />
            <input
              v-model="form.name"
              placeholder="Full name"
              class="input-field"
            />
          </div>
        </ValidationProvider>
        <!-- <ValidationProvider name="email" rules="required|email" class="w-full">
          <div class="flex mt-2 sm:mt-0">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-envelope"
              size="lg"
            />
            <input
              v-model="form.email"
              placeholder="Email"
              class="input-field"
            />
          </div>
        </ValidationProvider> -->
        <ValidationProvider
          name="phone"
          :rules="{ required: true, regex: /^[0-9]+$/, min: 2 }"
          class="w-full col-span-2"
        >
          <div class="flex mt-2 sm:mt-0">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-mobile-screen-button"
              size="lg"
            />
            <vue-country-code
              @onSelect="selectCountry"
              defaultCountry="ID"
              enabledCountryCode
              :enabledFlags="false"
              :onlyCountries="['MY', 'AU', 'ID', 'PH', 'SG', 'TH', 'DE']"
              style="border: none"
            ></vue-country-code>
            <div class="text-primary text-2xl">|</div>
            <input
              v-model="form.phone"
              placeholder="Phone number"
              class="input-field"
            />
          </div>
        </ValidationProvider>
        <!-- Password -->
        <ValidationProvider
          name="password"
          rules="required"
          class="w-full col-span-2"
        >
          <div class="flex mt-2 sm:mt-0">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
              size="lg"
            />
            <input
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              class="input-field"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />
            <div
              v-on:click="showPassword = !showPassword"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon
                v-if="showPassword"
                icon="fa-solid fa-eye"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-eye-slash"
                size="lg"
              />
            </div>
          </div>
          <!-- <span class="text-xs">
            <font-awesome-icon
              icon="fas fa-info-circle"
              class="text-primary text-sm"
            />
            Your password must contain at least 1 lowercase, 1 UPPERCASE, 1
            number, and 1 special character.
          </span> -->
        </ValidationProvider>
        <!-- Confirm Password -->
        <ValidationProvider
          name="password"
          rules="required"
          class="w-full col-span-2"
        >
          <div class="flex mt-2 sm:mt-0">
            <font-awesome-icon
              class="input-field-icon"
              icon="fa-solid fa-lock"
              size="lg"
            />
            <input
              v-model="form.confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              placeholder="Confirm Password"
              class="input-field"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />
            <div
              v-on:click="showConfirmPassword = !showConfirmPassword"
              class="cursor-pointer text-primary flex items-center"
            >
              <font-awesome-icon
                v-if="showConfirmPassword"
                icon="fa-solid fa-eye"
                size="lg"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-eye-slash"
                size="lg"
              />
            </div>
          </div>
        </ValidationProvider>
        <div class="col-span-2">
            <p class="mt-0.5 text-xs">
              <font-awesome-icon
                icon="fas fa-info-circle"
                class="text-primary text-xs"
              />
              Your password must contain at least 1 lowercase, 1 uppercase, 1
              number, and 1 special character.
            </p>
        </div>
        <div class="col-span-2"></div>
        <ValidationProvider
          name="agreement"
          :rules="{ required: { allowFalse: false } }"
          class="col-span-2 flex items-center justify-center mt-4 sm:mt-0"
        >
          <input v-model="form.agreement" type="checkbox" />
          <div
            @click="form.agreement = !form.agreement"
            class="cursor-pointer text-xs ml-2"
          >
            <span class="font-medium">By registering, I agree to</span>
            <span class="font-bold"> User Agreement and Privacy Policy</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="flex justify-center mt-8">
        <button
          @click="register"
          :disabled="invalid"
          class="button-submit text-lg px-20"
        >
          Sign Up
        </button>
      </div>
      <div class="text-center text-sm mt-4">
        <span class="font-medium">Already sign up ?</span>
        <span
          @click="$router.push({ name: 'Login' })"
          class="cursor-pointer font-bold"
        >
          Login</span
        >
      </div>
    </ValidationObserver>
    <div v-else>
      <div
        class="border rounded-3xl shadow-xl pt-4 pb-6 w-4/5 md:w-3/5 lg:w-2/5 mx-auto"
      >
        <div class="text-center text-xl text-gray-800 font-bold">
          Please verify your account
        </div>
        <div class="text-center text-gray-500 font-medium mt-2">
          We have sent a verification code to {{ formattedPhone }}.
        </div>
        <v-otp-input
          class="flex justify-center w-4/5 sm:w-3/5 mx-auto mt-6 gap-2"
          :num-inputs="6"
          separator=""
          input-type="number"
          input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
          @on-change="otp = $event"
        />
      </div>
      <button
        @click="verify"
        :disabled="isInvalidOtp"
        class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-12 block mx-auto rounded-lg mt-8"
      >
        Verify
      </button>
      <div class="text-center text-sm mt-8">
        <span class="font-medium">Didn't receive an OTP ?</span>
        <span @click="resendOtp" class="cursor-pointer font-bold">
          Resend OTP</span
        >
        <span v-show="sendOtpInCooldown">
          in
          <span class="text-red-500 font-semibold"
            >{{ otpTimerMinutes }} : {{ otpTimerSeconds }}</span
          >
        </span>
      </div>
      <div
        @click="resetRegistration"
        class="text-center cursor-pointer text-sm mt-4"
      >
        Entered a wrong number ?
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  data() {
    return {
      isInputProcess: true,
      form: {
        name: null,
        email: null,
        phone: null,
        password: null,
        confirmPassword: null,
        agreement: false,
        phoneCountryCode: null,
      },
      showPassword: false,
      showConfirmPassword: false,
      dialCode: 62,
      formattedPhone: null,
      otp: "",
      otpTimer: 0,
    };
  },

  computed: {
    isInvalidOtp() {
      return this.otp.toString().length != 6;
    },
    otpTimerMinutes() {
      const val = Math.floor(this.otpTimer / 60);
      return val < 10 ? `0${val}` : val;
    },
    otpTimerSeconds() {
      const val = this.otpTimer - this.otpTimerMinutes * 60;
      return val < 10 ? `0${val}` : val;
    },
    sendOtpInCooldown() {
      return this.otpTimer > 0;
    },
  },

  methods: {
    setOtpTimer(second = 300) {
      this.otpTimer = second;
      this.startOtpTimerCountdown();
    },
    startOtpTimerCountdown() {
      if (this.otpTimer > 0) {
        setTimeout(() => {
          this.otpTimer -= 1;
          this.startOtpTimerCountdown();
        }, 1000);
      }
    },
    selectCountry({ dialCode }) {
      this.form.phoneCountryCode = dialCode;
    },
    async register() {
      this.$store.commit("setOverlayLoading", true);

      if (this.form.phoneCountryCode == 62) {
        if (this.form.phone.slice(0, 1) == "0") {
          this.formattedPhone = this.form.phone;
        } else {
          this.formattedPhone = "0" + this.form.phone;
        }
      } else {
        this.formattedPhone = this.form.phoneCountryCode + this.form.phone;
      }

      const checkData = new FormData();
      checkData.append("txtTelepon", this.formattedPhone);

      try {
        if (this.form.password == this.form.confirmPassword) {
          // const phoneResponse = await this.$http.post(
          //   `${this.$apiTripwe}/ic/jetski-android-new/cek_telepon.php`,
          //   checkData
          // );
          // if (phoneResponse.data != "Wrong") {
          //   throw "Phone number already in use";
          // }
          const otpResponse = await this.$http.post(
            `${this.$apiTripweWebV2}/auth/register/validate`,
            {
              name: this.form.name,
              phone: this.form.phone,
              password: this.form.password,
              phone_country_code: this.form.phoneCountryCode,
              // email: this.form.email,
              // app: "WEBAPPS",
            }
          );
          if (otpResponse.data.status == 200 || otpResponse.data.status == 403) {
            var curTime = this.$moment();
            var nextReq = this.$moment(otpResponse.data.data.next_request_at);
            var diff = nextReq.diff(curTime, 'seconds');
            if(otpResponse.data.status == 403){
              this.$toasted.global.error(otpResponse.data.message);
            }else{
              this.$toasted.global.success(otpResponse.data.message);
            }
            this.setOtpTimer(diff);
            this.isInputProcess = false;
          }else{
            throw otpResponse.data.message;
          }
        } else {
          throw "Password doesn't match.\nMake sure that your password and repeated password is typed correctly";
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    async resendOtp() {
      if (this.sendOtpInCooldown) {
        this.$toasted.global.error(
          "Please wait a moment before sending new OTP"
        );
        return;
      }

      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.post(
          `${this.$apiTripweWebV2}/notifications/send-otp`,
          {
            phone: this.form.phone,
            phone_country_code: this.form.phoneCountryCode,
            scope: "REGISTER",
          }
        );

        if (response.data.status == 200 || response.data.status == 403) {
          var curTime = this.$moment();
          var nextReq = this.$moment(response.data.data.next_request_at);
          var diff = nextReq.diff(curTime, 'seconds');
          if(response.data.status == 403){
            this.$toasted.global.error(response.data.message);
          }else{
            this.$toasted.global.success(response.data.message);
          }
          this.setOtpTimer(diff);
        }else{
          throw response.data.message;
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    resetRegistration() {
      this.form.password = null;
      this.isInputProcess = true;
    },
    async verify() {
      this.$store.commit("setOverlayLoading", true);

      try {
        const res = await this.$http.post(
          `${this.$apiTripweWebV2}/auth/register`,
          {
            otp: this.otp,
            name: this.form.name,
            phone: this.form.phone,
            password: this.form.password,
            phone_country_code: this.form.phoneCountryCode,
            // email: this.form.email,
            // app: "WEBAPPS",
          }
        );

        if (res.data.status != "200") {
          var msg = res.data.message;
          if (msg != "" && msg != null) {
            throw msg;
          }
          throw "Registration failed";
        }

        this.$toasted.global.success("Registration success");
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
